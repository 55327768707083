import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../config';

const baseURL = new Config().getBaseURL()

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUsers():Observable<any>{
   return this.http.get(`${baseURL}/users/openAPI/AllUsers`)
  }

  getUserByRoles(rolename:any):Observable<any>{
    return this.http.get(`${baseURL}/users/openAPI/list_by_role?role=${rolename}`)
   }
}
