import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/internal/operators';

import { Socket } from 'ngx-socket-io';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

import { Config } from '../config'
import { Router } from '@angular/router';
const baseURL = new Config().getBaseURL()

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient, private socket: Socket) { }


  portfolioData = this.socket.fromEvent<any>('portfolioData');

  getBankingClassfier(companyId: any, params: any): Observable<any> {
    return this.http.post(`${baseURL}/companies/${companyId}/bankData`, params);
  }

  getAnalysisReort(companyId: any, type: any): Observable<any> {
    return this.http.get(`${baseURL}/companies/${companyId}/analysis?type=${type}`);
  }

  getCaseClosure(payload: object){
    return this.http.post(`${baseURL}/report/getClosureReport`, payload)
  }

  portfolioAnalysis(payload: object){
    return this.http.post(`${baseURL}/deal/reportsRouter/portfolioAnalysis`, payload)
  }

  portfolioList(queryParams: any){
    let {page, count} = queryParams
    return this.http.get(`${baseURL}/deal/reportsRouter/listPortfolioCuts?page=${page}&count=${count}`)
  }

  portfolioDownload(portfolioId: any){
    return this.http.get(`${baseURL}/deal/reportsRouter/${portfolioId}/downloadPortfolioReport`)
  }
}


