import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../config';
const baseURL = new Config().getBaseURL()

@Injectable({
  providedIn: 'root'
})
export class DealService {

  constructor(private http: HttpClient) { }

  getPromoterDetails(companyId: string, applicationId: string): Observable<any>{
    return this.http.get(`${baseURL}/deal/ops/${companyId}/${applicationId}/deals`)
  }

  getDealData(companyId: string, applicationId: string){
    return this.http.get(`${baseURL}/deal/mis/${companyId}/${applicationId}/getUpdateDealData`)
  }

  updateDealParams(companyId: string, applicationId: string, payload: object){
    return this.http.post(`${baseURL}/deal/mis/${companyId}/${applicationId}/updateDeal`, payload)
  }

  getGSTINs(companyId: string){
    return this.http.get(`${baseURL}/deal/mis/${companyId}/gstins`)
  }

  getVirtualAccount(companyId: string, applicationId:string, fundingPartner: string){
    return this.http.get(`${baseURL}/deal/OpsExecutive/${companyId}/${applicationId}/${fundingPartner}/getVirtualAccount`)
  }
}
