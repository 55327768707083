import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../config';
const baseURL = new Config().getBaseURL();

@Injectable({
  providedIn: 'root'
})
export class CrimeCheckService {

  constructor(private http: HttpClient) { }

  getCrimeCheck(applicationId, companyId): Observable<any>{
    return this.http.get(`${baseURL}/companies/${applicationId}/${companyId}/getCrimeCheck`)
  }

  getCrimeCheckList(companyId, applicationId): Observable<any>{
    return this.http.get(`${baseURL}/companies/${companyId}/${applicationId}/getCrimeCheckList`)
  }

  fetchCompanyDetails(companyId: string): Observable<any>{
    return this.http.get(`${baseURL}/companies/${companyId}/fetchCompanyDetails`)
  }

  addReportCrimeCheck(payload): Observable<any>{
    return this.http.post(`${baseURL}/companies/company/addReportCrimeCheck?type=company`, payload)
  }

  downloadJSON(requestId){
    return this.http.get(`${baseURL}/companies/${requestId}/downloadJsonCrimeCheck`)
  }
}
